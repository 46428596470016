<template>
  <q-page padding>
    <q-card flat bordered>
      <q-card-section>
        <div class="text-h6">操作日志审计</div>
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <x-responsive-table
          ref="table"
          url="/operationLog/logPage"
          v-model:param="param"
          :paramProps="paramProps"
          :columns="columns"
          row-key="id"
          title-prop="templateName"
        >
          <template v-slot:search>
            <q-input v-model="param.username" label="用户名" placeholder="请输入用户名" clearable dense outlined/>
            <q-input v-model="param.realName" label="真实姓名" placeholder="请输入真实姓名" clearable dense outlined/>
            <q-select v-model="param.operationType" label="操作类型" :options="$dict.operationType" map-options emit-value clearable dense outlined/>
            <x-datetime v-model="param.createTimeStart" label="操作时间(起)" clearable dense outlined/>
            <x-datetime v-model="param.createTimeEnd" label="操作时间(止)" clearable dense outlined/>
          </template>
          <template v-slot:opes="{row}">
            <q-btn label="查看" color="primary" size="sm" flat @click="toDetail(row.id)"/>
          </template>
        </x-responsive-table>
      </q-card-section>
    </q-card>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showDetailDialog" persistent>
      <q-card style="width:470px;max-width:90vw">
        <q-card-section class="row items-center">
          <div class="text-h6">操作日志详情</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <operation-log-form ref="areaForm" :data="form" :readonly="true"/>
        </q-card-section>
        <q-separator/>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import { XResponsiveTable } from '../../../components/responsive-table/index'
import { XDatetime } from '../../../components/datetime'
import OperationLogForm from './OperationLogForm'

export default {
  components: {
    XResponsiveTable,
    XDatetime,
    OperationLogForm
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const columns = ref([
      { label: 'ID', name: 'id', field: 'id', align: 'left', sortable: true },
      { label: '用户名', name: 'username', field: 'username', align: 'left', sortable: true },
      { label: '真实姓名', name: 'realName', field: 'realName', align: 'left', sortable: true },
      { label: '操作类型', name: 'operationType', field: 'operationType', align: 'left', sortable: true },
      { label: '操作时间', name: 'createTime', field: 'createTime', align: 'left', sortable: true },
      { label: '备注', name: 'remark', field: 'remark', align: 'left', sortable: true }
    ])
    const param = ref({
      username: null,
      realName: null,
      operationType: null,
      createTimeStart: null,
      createTimeEnd: null,
      include: [
        'id',
        'username',
        'realName',
        'operationType',
        'createTime',
        'remark'
      ]
    })
    const paramProps = ref({})
    const form = ref(null)
    const showDetailDialog = ref(false)

    async function toDetail (id) {
      form.value = await proxy.$server.post('/operationLog/getLogDetail', { id })
      showDetailDialog.value = true
    }

    return {
      columns,
      param,
      paramProps,
      form,
      showDetailDialog,
      toDetail
    }
  }
}
</script>
