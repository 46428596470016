<template>
  <q-form ref="formEle" class="q-gutter-md">
    <q-field
      label="ID"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.id}}</span>
    </q-field>
    <q-field
      label="用户名"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.username}}</span>
    </q-field>
    <q-field
      label="真实姓名"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.realName}}</span>
    </q-field>
    <q-field
      label="操作类型"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.operationType}}</span>
    </q-field>
    <q-field
      label="操作时间"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.createTime}}</span>
    </q-field>
    <q-field
      label="备注"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.remark}}</span>
    </q-field>
    <q-field
      label="变更记录"
      :readonly="readonly"
      stack-label
      dense
      outlined
    >
      <span>{{form.changeLog}}</span>
    </q-field>
  </q-form>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const form = ref(dataToForm(props.data))

    function dataToForm (data) {
      return Object.assign({
        bizId: null,
        userId: null,
        username: null,
        realName: null,
        operationType: null,
        changeLog: null,
        remark: null,
        createTime: null
      }, data)
    }

    return {
      form
    }
  }
}
</script>
